<template>
  <div class="container">
    <table-page dataName="list"
                ref="table"
                :search="search"
                :searchFun="searchFun"
                :request="sutPayList">
      <el-table-column prop="activity_id"
                       show-overflow-tooltip
                       label="活动ID">
      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="活动名称">
      </el-table-column>
      <el-table-column label="活动区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{`${scope.row.list_province || ''}${scope.row.list_city || ''}${scope.row.list_district || ''}${scope.row.list_net || ''}`}}</span>
        </template>

      </el-table-column>
      <el-table-column label="操作"
                       width="250px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="text"
                     @click="linkUrl(scope.row.data_url)">查看活动数据</el-button>
          <el-button type="text"
                     @click="linkUrl(scope.row.img_url)">查看活动码</el-button>
        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { sutPayList } from '@/api/market'
export default {
  name: 'Rewards',
  data () {
    return {
      sutPayList,
      search: [{ type: 'area' }, {
        type: 'input',
        name: '活动Id',
        placeholder: '请输入活动Id',
        key: 'activity_id',
        value: ''
      }, {
        type: 'input',
        name: '活动名称',
        placeholder: '请输入活动名称',
        key: 'title',
        value: ''
      }]
    }
  },
  components: {
    TablePage
  },
  methods: {
    searchFun (res) {
      const param = Object.assign({}, res)
      param.list_province = res.province
      param.list_city = res.city
      param.list_district = res.district
      param.list_net = res.net
      delete param.province
      delete param.city
      delete param.district
      delete param.net
      return param
    },
    linkUrl (url) {
      window.open(url, '_blank')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
